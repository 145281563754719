<template>
	<MhView :isLoading="isLoading">
		
		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>			
		
		<div class="border border--bottom row" v-if="$root.isSmallDevice">
			<div class="viewTitle vSpace vSpace--default hSpace hSpace--big col">
				<h2 v-html="$root.getTranslatedValue('Forschung')"></h2>
			</div>
		</div>		

		<div class="researchPost" v-for="post in posts" :id ="post.slug" :key="post.id">
			
			<div class="toggleContent border border--bottom row">
				<h3 class="hSpace hSpace--big vSpace vSpace--default col col--11of12">
					<span class="researchPost__postTitle font font--sizeBig">
						{{$root.getTranslatedAcfValue( post.acf, 'postTitle')}}
					</span>
					<span class="researchPost__postRubrik font font--sizeSmall font--uppercase">
						{{$root.getTranslatedAcfValue( post.acf, 'rubrik')}}
					</span>
				</h3>
				<div class="hSpace hSpace--big hSpace--notLeft  vSpace vSpace--default col col--1of12">
					<a class="toggleContent__btn" :class="{'toggleContent__btn--isActive' : !post.isFolded}" @click="toggleContent( post )"></a>
				</div>				
			</div>
			
			<div class="noFlexGrow border border--bottom row" :class="{'toggleContent__content' : post.isFolded }">
				<div 	class="border hSpace hSpace--big vSpace vSpace--default col col--6of12 col--sm-12of12" 
						:class="{'border border--right' : !$root.isSmallDevice}"
						v-html="$root.getTranslatedAcfValue( post.acf, 'text')"></div>
				<div 	class="border border--right hSpace hSpace--medium vSpace vSpace--default col col--3of12 col--sm-12of12"
						:class="{'border border--right' : !$root.isSmallDevice}">
					<AsidePairs :post="post"></AsidePairs>
				</div>
				<div class="col col--3of12 col--sm-12of12">
					<MhImage :imageObject="post.acf.image" v-if="post.acf.image"></MhImage>
				</div>
			</div>
			
		</div>		
		
	</MhView>
</template>

<script>
	// @ is an alias to /src
	import MhView from '@/components/MhView/MhView.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import AsidePairs from '@/components/AsidePairs.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'researchView',
		components: {
			MhView,
			MhImage,
			AsidePairs,
			MobilePageTitle,
		},	
		mixins: [ RestHandler ],	
		data() {
			return {
				isLoading: true,
				posts: [],
			}
		},
		methods: {
			getImageContainerStyles( post ) {
				if( post && post.acf && post.acf.image ){
					const width = post.acf.image.width
					const height = post.acf.image.height
					const imageSrc = post.acf.image.sizes.medium
					
					return {
						paddingBottom: (height/width) * 100 + '%',
						backgroundImage: 'url('+imageSrc+')',
					}			
				}
			},				
			toggleContent ( post ) {
				post.isFolded = !post.isFolded
			},
		},
		mounted() {
			
			// fetch publicationPosts
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json/wp/v2/researchPosts',
				params : {},
				callback : (response) => {
					this.posts = response.data
					this.isLoading = false
					
					// add new prop to handle post folding on mobile
					this._.map(this.posts, (post) => {
						this.$set( post, 'isFolded', true )
					});					
					
					// wenn man von der suche kommt ist der slug mit in der url,
					// und zu dem soll gleich gescrollt werden					
					if( this.$route.params.postSlug ){
						setTimeout( () => {
							const element = document.getElementById( this.$route.params.postSlug );
							element.scrollIntoView( {block: "start", behavior: "smooth"} )
						}, 150)
					}
				},
			})
			
		},	
	}
</script>

<style lang="less" scoped>	
	.researchPost {}
	.researchPost__postTitle {
		padding-right: 1em;
	}
</style>
